'use client';

import { useRouter } from 'next/navigation';
import type { ButtonProps } from '~/components/ui/button';
import { Button } from '~/components/ui/button';

export default function LoginButton(props: ButtonProps) {
  const router = useRouter();

  return (
    <Button
      variant="outline"
      size="sm"
      {...props}
      onClick={() => {
        router.push(`/login?callbackUrl=${window.location.href}`);
      }}
    >
      Login
    </Button>
  );
}
